<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :search="search"
            :loading="loadingTable"
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :key="`list-users-${tenantId}`"
            :items-per-page="15"
            :options.sync="options"
            loading-text="Cargando tiendas..."
            no-results-text="No se han encontrado tiendas"
            no-data-text="Sin tiendas"
            class="elevation-1"
            dense
        >
            <template v-slot:top>
                <v-row align="center" justify="center">
                <v-col cols="12" md="3" class="text-center">
                    <v-toolbar-title>
                    <v-icon>mdi-store</v-icon> TIENDAS
                    </v-toolbar-title>
                </v-col>
                <v-col md="6">
                    <v-text-field v-model="search" label="Buscar" />
                </v-col>
                <v-col md="3" class="text-center">
                    <v-btn small color="primary" @click="formStore()">
                    <v-icon>mdi-plus</v-icon> Nueva tienda
                    </v-btn>
                </v-col>
                </v-row>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="formStore(item.product_store_id)"
                        >
                            <v-icon color="orange">mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog
			v-model="dialogFormPs"
			persistent
			max-width="900px"
		>	
            <v-overlay :value="overlay" absolute>
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
			<v-card>
				<v-card-title>
					<span class="headline"> 
                        <v-icon>mdi-store</v-icon> TIENDAS
                    </span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="formPs">
							<v-row>
								<v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.store_name" 
                                        label="Nombre de tienda"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.company_name" 
                                        label="Nombre de empresa"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        v-model="datosPs.title" 
                                        label="Título"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        v-model="datosPs.subtitle" 
                                        label="Subtítulo"
                                    />
								</v-col>
                                <v-col md="3">
                                    <v-autocomplete
                                        v-model="datosPs.inventory_facility_id"
                                        :items="facilities"
                                        item-text="facility_name"
                                        item-value="facility_id"
                                        label="Inventario"
                                    />
								</v-col>
                                <v-col md="3">
                                    <v-autocomplete
                                        v-model="datosPs.reserve_order_enum_id"
                                        :items="reservationTypes"
                                        item-text="description"
                                        item-value="enum_id"
                                        label="Tipo de reserva de inventario"
                                    />
								</v-col>
                                <v-col md="3">
                                    <v-autocomplete
                                        v-model="datosPs.reserve_order_enum_id"
                                        :items="saleChannels"
                                        item-text="description"
                                        item-value="enum_id"
                                        label="Canal de ventas"
                                    />
								</v-col>
                                <v-col md="3">
                                    <v-autocomplete
                                        v-model="datosPs.punto_emision_envio_dom"
                                        :items="terminals"
                                        item-text="terminal_name"
                                        item-value="pos_terminal_id"
                                        label="Punto de emisión"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        v-model="datosPs.order_number_prefix" 
                                        label="Prefijo de orden"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        v-model="datosPs.default_currency_uom_id"
                                        label="Moneda"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        v-model="datosPs.codigo_establecimiento"
                                        label="Cod. establecimiento"
                                        v-mask="'###'"
                                    />
								</v-col>
                                <v-col md="3">
                                    <v-autocomplete
                                        v-model="datosPs.punto_emision_envio_dom"
                                        :items="typeStores"
                                        label="Tipo de tienda"
                                    />
								</v-col>
							</v-row>
                            <v-col cols="12" class="mt-2">
                                <v-img src="../../assets/images/placetopay.png" width="430px" height="100px"/>
                            </v-col>
                            <v-row class="mt-3">
                                <v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.login_place_to_pay" 
                                        label="LOGIN KEY"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.access_key_place_to_pay" 
                                        label="ACCESS KEY"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.url_place_to_pay" 
                                        label="URL"
                                    />
								</v-col>
                                <v-col md="3">
									<v-text-field 
                                        :rules="required" 
                                        v-model="datosPs.secret_key_place_to_pay"
                                        label="SECRET KEY"
                                    />
								</v-col>
                            </v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions >
					<v-col class='text-center'>
						<v-btn
							class="mx-4"
							color="primary"
							@click="guardarProductStore"
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							class="mx-4"
							@click="closeDialogFormPs"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>	
    </div>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex"

export default {
    name: "TiendasComponent",
    data: () => ({
        dataTable: [],
        dialog: false,
        overlay: false,
        search: "",
        options: {},
        currentPage: 1,
        dialogFormPs:false,
        datosPs:{},
        facilities:[],
        reservationTypes:[],
        saleChannels:[],
        terminals:[],
        typeStores:['TIENDA','MATRIZ'],
        headers: [
            { text: "ID", value: "product_store_id" },
            { text: "Nombre", value: "store_name" },
            { text: "Empresa", value: "company_name" },
            { text: "Tipo de tienda", value: "type_store" },
            { text: "Estabelcimiento", align: "center", value: "codigo_establecimiento" },
            { text: "Actions", align: "start", value: "actions", align: "center" },
        ],
        required:[
            v => !!v || 'El campo es requerido'
        ],
    }),
    watch: {
        options: {
            handler() {
                this.getTiendas()
            },
            deep: true,
        },
    },
    computed: {
        ...mapState("master", ["loadingTable", "tenantId"]),

        ...mapState("administration", ["menu"]),
    },
    methods: {

        ...mapMutations("master", [
            "setMenu",
            "setUrl",
            "setLoadingTable",
            "setTitleToolbar",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        getTiendas() {

            this.setLoadingTable(true);

            this.dataTable = [];

            this.setUrl("product-store");
            this.requestApi({
                method: "GET",
                data: {
                    tipo_lista: "listaProductStore",
                    page: this.currentPage,
                },
            })
            .then(res => {
                
                console.log('res',res)

                this.dataTable = res.data._embedded.product_store
                
            })
            .then(() => {

                this.setLoadingTable(false)

            })

        },

        formStore(product_store_id = null) {

            this.setUrl("product-store")
            this.overlay = true

            this.requestApi({
                method: "GET",
                data: {
                    tipo_lista: "datosFormProductStore"
                },
            })
            .then(res => {

                const { facilities, enumerations, posTerminals } = res.data.detail

                this.facilities = facilities
                this.reservationTypes = enumerations.filter(e => e.enum_type_id == 'INV_RES_ORDER')
                this.saleChannels = enumerations.filter(e => e.enum_type_id == 'ORDER_SALES_CHANNEL')
                this.terminals = posTerminals

                if(product_store_id!= null)
                    this.datosPs = this.dataTable.find(e => e.product_store_id == product_store_id)

                this.dialogFormPs = true
                this.overlay = false
                
                
            })
            .catch(() => {

                this.overlay = false

            })

        },

        closeDialogFormPs(){

            this.dialogFormPs = false
            this.datosPs = {}

        },

        guardarProductStore(){
         
            this.setUrl("product-store")
            this.overlay = true

            this.requestApi({
                method: "POST",
                data: {
                    typeStore: "saveProductStore",
                    productStore: this.datosPs
                },
            })
            .then(res => {
                
                this.getTiendas()
                
                this.closeDialogFormPs()

                this.alertNotification({
                    param:{
                        html: res.data.res.msg
                    }
                })

                this.overlay = false
                
            })
            .catch(() => {

                this.overlay = false

            })
        },

    },
    mounted() {

        this.getTiendas()

    },
               
}

</script>